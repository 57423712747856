<template>
  <div class="user">
    <el-card>
      <el-form :inline="true" label-width="90px" label-position="left">
        <el-form-item label="微信名：">
          <el-input v-model="queryInfo.nickname" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input
            v-model="queryInfo.phone"
            type="number"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="btn" @click="search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :header-cell-style="{
          backgroundColor: '#F0F0FF',
          color: '#151515',
          fontWeight: 'bold',
        }"
        :data="tableData"
        style="width: 100%"
        height="650px"
        stripe
        class="tab_data"
      >
        <el-table-column prop="headImgUrl" label="微信头像" align="center">
          <template v-slot="{ row }">
            <el-image :src="row.headImgUrl" />
          </template>
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="微信名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="注册时间"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import { userList } from "@/api/user.js";
import { pagination } from "@/mixins/pagination";
const defaultQueryInfo = Object.freeze({
  pageNumber: 0, // 页码
  pageSize: 10, //每页显示个数
  nickname: "", //微信号
  phone: "", //手机
});
export default {
  name: "User",
  mixins: [pagination],
  created() {
    this.search();
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      tableData: [],
    };
  },
  methods: {
    //重置查询条件
    reset() {
      Object.assign(this.queryInfo, defaultQueryInfo);
      this.search();
    },
    //获取首页数据
    async search() {
      const { data: res } = await userList(this.queryInfo);
      if (res.success) {
        this.tableData = res.data.data;
        this.total = res.data.pageTotal;
        console.log(res.data.data)
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  width: 100%;

  .tab_data {
    /deep/ .el-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  /deep/ .el-card {
    border: none;
  }

  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>